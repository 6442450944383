@font-face {
	font-family: "icons";
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
		url('../fonts/icons/icons.woff2') format('woff2'),
		url('../fonts/icons/icons.woff') format('woff'),
		url('../fonts/icons/icons.ttf') format('truetype'),
		url('../fonts/icons/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}
.icons:before {
	display: inline-block;
	vertical-align: middle;
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}