@import '_config';
@import '_sprite';
@import '_sprite_mixin';
@import '_iconsMixin';
@import './_plugins/_magic';

/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 4.
*/

/*
見出し1

headLine1

Markup:
<h1 class="headLine1">見出し1</h1>

Styleguide 4.1.1
============================================================*/
.headLine1 {
	$this: &;
	@include mintyo();
	font-size: rem(26px, pc);
	font-weight: bold;
	border-bottom: 3px solid $gray-border;
	position: relative;
	padding: 0 0 10px;
	margin: 40px 0;
	line-height: 1.4;
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
		height: 3px;
		width: 275px;
		background: $aqua;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine1 {
		$this: &;
		font-size: rem(20px, sp);
		margin: 30px 0;
	}
}

/*
見出し2

headLine2

Markup:
<h1 class="headLine2">見出し2</h1>

Styleguide 4.1.2
============================================================*/
.headLine2 {
	$this: &;
	@include mintyo();
	font-size: rem(22px, pc);
	font-weight: bold;
	position: relative;
	padding: 0 0 10px 20px;
	margin: 40px 0;
	line-height: 1.4;
	border-bottom: 1px dotted $gray-dot;
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 10px;
		height: calc(100% - 10px);
		width: 5px;
		background: $aqua;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine2 {
		$this: &;
		font-size: rem(18px, sp);
		margin: 30px 0;
	}
}

/*
見出し3

headLine3

Markup:
<h1 class="headLine3">見出し3</h1>

Styleguide 4.1.3
============================================================*/
.headLine3 {
	$this: &;
	font-size: rem(22px, pc);
	font-weight: bold;
	background: $gray-bg;
	border-radius: 5px;
	padding: 8px 20px;
	margin: 60px 0 30px;
}


@media screen and (max-width: $breakpoint-sp){
	.headLine3 {
		$this: &;
		font-size: rem(16px, sp);
		margin: 60px 0 30px;
	}
}

/*
ボタン

btn

Markup:
<ul class="btns btns-hr">
	<li class="btns_btn"><a href="" class="btn {$modifiers}">ボタン</a></li>
	<li class="btns_btn"><button class="btn {$modifiers}">ボタン</button></li>
</ul><!-- /.btns -->

btn-min - min
btn-border - border
btn-arrowUp - arrowUp
btn-arrowRight - arrowRight
btn-arrowDown - arrowDown
btn-arrowLeft - arrowLeft

Styleguide 4.2.1
============================================================*/
.btn {
	$this: &;
	display: block;
	width: 100%;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: #fff;
	background: $aqua;
	font-size: rem(18px, pc);
	font-weight: bold;
	border-radius: 5px;
	padding: 14px;

	&-min {
		font-size: rem(15px, pc);
		padding: 10px;
	}

	&-border {
		background: #fff;
		border: 1px solid $aqua;
		color: $aqua;
	}

	&-arrowUp {
		&:before {
			@include icons($icons-circleArrow-up);
			margin: 0 8px 0 0;
			vertical-align: -2px;
		}
	}
	&-arrowRight {
		&:before {
			@include icons($icons-circleArrow-right);
			margin: 0 8px 0 0;
			vertical-align: -2px;
		}
	}
	&-arrowDown {
		&:before {
			@include icons($icons-circleArrow-down);
			margin: 0 8px 0 0;
			vertical-align: -2px;
		}
	}
	&-arrowLeft {
		&:before {
			@include icons($icons-circleArrow-left);
			margin: 0 8px 0 0;
			vertical-align: -2px;
		}
	}
}
@media screen and (min-width: $breakpoint-sp +1){
	.btn {
		&:hover {
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.btn {
		$this: &;
	}
}

/*
ボタンレイアウト

btns

Markup:
<ul class="btns {$modifiers}">
	<li class="btns_btn btns_btn-s"><a href="" class="btn">ボタン小</a></li>
	<li class="btns_btn btns_btn-m"><a href="" class="btn">ボタン中</a></li>
	<li class="btns_btn btns_btn-l"><a href="" class="btn">ボタン大</a></li>
</ul><!-- /.btns -->

.btns-hr - 横並び
.btns-left - 左寄せ
.btns-right - 右寄せ

Styleguide 4.2.2
============================================================*/
.btns {
	$this: &;
	margin: 45px -10px 0;
	& > :first-child {
		& > :first-child {
			margin-top: 0;
		}
	}
	&_btn {
		margin: 20px 0 0;
		&-s {
			width: 280px;
		}
		&-m {
			width: 465px;
		}
		&-l {
		}
	}

	&-left {
		#{$this}_btn {
			margin-right: auto;
		}
	}
	&-right {
		#{$this}_btn {
			margin-left: auto;
		}
	}

	&-hr {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -10px;
		& > :first-child {
		}
		#{$this}_btn {
			margin: 20px 10px 0;
		}
	}
	&-hr.btns-left {
		justify-content: flex-start;
	}
	&-hr.btns-right {
		justify-content: flex-end;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.btns {
		$this: &;
		&_btn {
			margin: 30px 0 0;
			& > :first-child {
				margin-top: 0;
			}
			&-s {
				width: auto;
			}
			&-m {
				width: auto;
			}
			&-l {
				width: auto;
			}
		}

		&-hr {
			display: block;
			margin: 30px 0 0;
			& > :first-child {
				margin-top: 0;
			}
			#{$this}_btn {
				margin: 15px 0 0;
				width: 100%;
			}
		}
	}
}

/*
youtubeのレスポンシブ化

youtube

Markup:
<div class="youtube"><iframe src="https://www.youtube.com/embed/SV95pdw3pDw?rel=0?ecver=2" width="640" height="360" frameborder="0" allowfullscreen></iframe></div>

Styleguide 4.3
============================================================*/
@media screen and (max-width: $breakpoint-sp){
	.youtube {
		position: relative;
		padding-top: calc(100% * 360 / 640);
		margin-top: 20px;
		& > iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/*
ブロックになるテーブル

responsiveTable

Markup:
<table class="responsiveTable">
	<tbody>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
		<tr>
			<th>あああああああああ</th>
			<td>いいいいいいいいいいいいいいいいいいいいいいいいいいい</td>
		</tr>
	</tbody>
</table>

Styleguide 4.4
============================================================*/
@media screen and (max-width: $breakpoint-sp){
	.responsiveTable {
		display: block;
		& > tbody {
			display: block;
			border: none;
			padding: 0;
			& > :first-child {
				margin-top: 0;
			}
			& > tr {
				display: block;
				border: none;
				padding: 0;
				margin: 20px 0 0;
				& > th,
				& > td {
					display: block;
					border: none;
					padding: 5px;
				}
			}
			& > tr {
				& > th {
				}
				& > td {
				}
			}
		}
	}
}


/*
レスポンシブでdata-thが出るテーブル

dataThTable

Markup:
<table class="dataThTable">
	<thead>
		<tr>
			<th>見出し1</th>
			<th>見出し2</th>
			<th>見出し3</th>
			<th>見出し4</th>
			<th>見出し5</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td data-th="見出し1">内容1</td>
			<td data-th="見出し2">内容2</td>
			<td data-th="見出し3">内容3</td>
			<td data-th="見出し4">内容4</td>
			<td data-th="見出し5">内容5</td>
		</tr>
	</tbody>
</table>

Styleguide 4.5
============================================================*/

@media screen and (max-width: $breakpoint-sp){
	.dataThTable {
		display: block;
		border: 1px solid $gray-border;
		padding: 10px;
		& > thead {
			display: none;
		}
		& > tbody {
			display: block;
			border: none;
			padding: 0;
			& > :first-child {
				padding-top: 0;
				border-top: none;
			}
			& > tr {
				display: block;
				border: none;
				border-top: 1px solid $gray-border;
				padding: 10px 0;
				& > th,
				& > td {
					display: block;
					border: none;
					padding: 0;
				}
				& > [data-th] {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					&:before {
						content: attr(data-th)'：';
						font-weight: bold;
					}
				}
			}
		}
	}
}

/*
レスポンシブでスクロールになるテーブル

scrollTable

Markup:
<div class="scrollTable">
	<table>
		<thead>
			<tr>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
				<th>見出し</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>見出し</th>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
			</tr>
			<tr>
				<th>見出し</th>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
			</tr>
			<tr>
				<th>見出し</th>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
				<td>内容内容</td>
			</tr>
		</tbody>
	</table>
</div><!-- /.scrollTable -->

Styleguide 4.6
============================================================*/
.scrollTable {
	$this: &;
	margin: 20px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.scrollTable {
		$this: &;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		& > table {
			width: auto;
			white-space: nowrap;
		}
	}
}