@import '_config';
@import '_sprite';
@import '_sprite_mixin';
@import '_iconsMixin';
@import './_plugins/_magic';

.pageID-top {
	/*============================================================
	  大枠
	============================================================*/
	.contents {
		margin-top: 45px;
		position: relative;
	}
	.topContainer {
		$this: '.topContainer';
		margin: 53px 0 0;
		overflow: hidden;
		&_left {
			float: left;
			width: 880px;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_right {
			float: right;
			width: 264px;
			& > :first-child {
				margin-top: 0;
			}
		}
	}

	@media screen and (max-width: $breakpoint-sp){
		.contents {
			margin: 40px 0 0;
		}
		.topContainer {
			$this: '.topContainer';
			margin: 40px 0 0;
			&_left {
				float: none;
				width: auto;
			}
			&_right {
				margin: 40px 0 0;
				float: none;
				width: auto;
			}
		}

	}


	/*============================================================
	  .mainImage
	============================================================*/
	.mainImage {
		$this: &;
		&_item {
			margin: 0;
			& > img {
				width: 100%;
			}
		}
	}
	@media screen and (max-width: $breakpoint-sp){
		.mainImage {
			$this: &;
			overflow: hidden;
			&_item {
				& > img {
					max-width: none;
					width: 190%;
					position: relative;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	/*============================================================
	  .topSearch
	============================================================*/
	.topSearch {
		$this: '.topSearch';
		display: flex;
		margin: 0 -30px;
		& > :first-child {
			border-left: none;
		}
		&_col {
			width: calc(100%/3);
			text-decoration: none;
			color: inherit;
			display: flex;
			align-items: center;
			padding: 0 30px;
			&:before {
				flex-grow: 0;
				flex-shrink: 0;
				width: 92px;
				height: 92px;
				text-align: center;
				line-height: 92px;
				border-radius: 5px;
				color: #fff;
				margin: 0 20px 0 0;
				background: #888;
			}
			&-map {
				&:before {
					@include icons($icons-map);
					background: $pink;
					font-size: 47px;
				}
				#{$this}_name {
					color: $pink;
				}
			}
			&-subject {
				&:before {
					@include icons($icons-examination);
					background: $green;
					font-size: 50px;
				}
				#{$this}_name {
					color: $green;
				}
			}
			&-initial {
				&:before {
					@include icons($icons-japanese-a);
					background: $yellow;
					font-size: 48px;
				}
				#{$this}_name {
					color: $yellow;
				}
			}
		}
		#{$this}_col + #{$this}_col {
			border-left: 1px dotted $gray-dot;
		}
		&_body {
			& > :first-child {
				margin-top: 0;
			}
		}
		&_name {
			@include mintyo();
			font-size: rem(22px, pc);
			font-weight: bold;
		}
		&_txt {
			font-size: rem(15px, pc);
			margin: 8px 0 0;
		}
	}
	@media screen and (max-width: $breakpoint-sp){
		.topSearch {
			$this: '.topSearch';
			display: block;
			margin: 0 0 -15px;
			&_col {
				width: auto;
				padding: 0 0 10px;
				&:before {
					width: 66px;
					height: 66px;
					line-height: 66px;
				}
				&-map {
					&:before {
						font-size: 40px;
					}
				}
				&-subject {
					&:before {
						font-size: 42px;
					}
				}
				&-initial {
					&:before {
						font-size: 36px;
					}
				}
			}
			#{$this}_col + #{$this}_col {
				border-left: none;
				border-top: 1px dotted $gray-dot;
				padding-top: 10px;
			}
			&_body {
			}
			&_name {
				font-size: rem(16px, sp);
			}
			&_txt {
				font-size: rem(14px, sp);
			}
		}
	}

	.picFrom {
		position: absolute;
		margin: 0;
		font-size: rem(12px, pc);
		right: 0;
		top: -50px;
	}
	@media screen and (max-width: $breakpoint-sp){
		.picFrom {
			top: -30px;
			right: 10px;
		}
	}

}


/*============================================================
  .topLecture
============================================================*/
.topLecture {
	$this: '.topLecture';
	display: flex;
	align-items: center;
	&_title {
		flex-grow: 0;
		flex-shrink: 0;
		width: 140px;
		height: 140px;
		text-align: center;
		line-height: 140px;
		border-radius: 50%;
		background: $aqua-light;
		color: $aqua;
		margin: 0 50px 0 0;
		font-size: rem(21px, pc);
		@include mintyo();
		font-weight: bold;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 5px);
			right: -9px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5.5px 0 5.5px 9px;
			border-color: transparent transparent transparent $aqua-light;
		}
	}
	&_body {
		text-decoration: none;
		color: inherit;
		display: block;
	}
	&_name {
		margin: 0;
		& > a {
			display: block;
			font-size: rem(20px, pc);
			font-weight: bold;
			text-indent: -25px;
			color: $txtColor;
			&:before {
				@include icons($icons-circleArrow-right);
				color: $aqua;
				margin: 0 8px 0 0;
				font-size: 17px;
				vertical-align: middle;
			}
		}
	}
	&_infos {
		display: flex;
		align-items: flex-start;
		margin: 15px 0 0 -8px;
	}
	&_info {
		display: flex;
		width: calc(100%/2 - 8px);
		margin: 0 0 0 8px;
		dt {
			flex-grow: 0;
			flex-shrink: 0;
			width: 66px;
			height: 66px;
			text-align: center;
			line-height: 66px;
			border: 1px solid $aqua;
			border-radius: 5px;
			color: $aqua;
			font-size: rem(17px, pc);
			font-weight: bold;
			margin: 0 8px 0 0;
		}
		dd {
			align-self: center;
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.topLecture {
		$this: '.topLecture';
		display: block;
		&_title {
			width: auto;
			height: auto;
			line-height: normal;
			border-radius: 0;
			margin: 0;
			font-size: rem(18px, sp);
			padding: 8px 10px;
			&:before {
				content: none;
			}
		}
		&_body {
			margin: 15px 0 0;
		}
		&_name {
			& > a {
				padding-left: 25px;
				font-size: rem(15px, sp);
			}
		}
		&_infos {
			display: block;
			margin: 0;
		}
		&_info {
			margin: 10px 0 0;
			width: auto;
			dt {
				height: auto;
				line-height: normal;
				vertical-align: middle;
				padding: 5px 0;
				margin: 0 10px 0 0;
				&:before {
					content: '';
					display: inline-block;
					vertical-align: middle;
					letter-spacing: normal;
					width: 0;
					height: 100%;
				}
			}
		}
	}
}