@import '_config';
@import '_sprite';
@import '_sprite_mixin';
@import '_iconsMixin';
@import './_plugins/_magic';

/*
便利クラス

マージン強制とかの便利クラス

Styleguide 1.
*/

/* 汎用
-------------------------------------------------------------*/
@for $i from 0 through 100 {
	.mt#{$i} {
		margin-top: #{$i}px !important;
	}
	.mr#{$i} {
		margin-right: #{$i}px !important;
	}
	.mb#{$i} {
		margin-bottom: #{$i}px !important;
	}
	.ml#{$i} {
		margin-left: #{$i}px !important;
	}
	.mt#{$i}e {
		margin-top: #{$i * .1}em !important;
	}
	.mr#{$i}e {
		margin-right: #{$i * .1}em !important;
	}
	.mb#{$i}e {
		margin-bottom: #{$i * .1}em !important;
	}
	.ml#{$i}e {
		margin-left: #{$i * .1}em !important;
	}
	.pt#{$i} {
		padding-top: #{$i}px !important;
	}
	.pr#{$i} {
		padding-right: #{$i}px !important;
	}
	.pb#{$i} {
		padding-bottom: #{$i}px !important;
	}
	.pl#{$i} {
		padding-left: #{$i}px !important;
	}
	.pt#{$i}e {
		padding-top: #{$i * .1}em !important;
	}
	.pr#{$i}e {
		padding-right: #{$i * .1}em !important;
	}
	.pb#{$i}e {
		padding-bottom: #{$i * .1}em !important;
	}
	.pl#{$i}e {
		padding-left: #{$i * .1}em !important;
	}
	.indent#{$i}e {
		text-indent: #{$i*-.1}em !important; padding-left: #{$i*.1}em !important;
	}
	.w#{$i*10} {
		width: #{$i*10}px !important;
	}
	.w#{$i}p {
		width: #{$i}% !important;
	}
	@media screen and (max-width: $breakpoint-sp){
		.mt#{$i}-sp {
			margin-top: #{$i}px !important;
		}
		.mr#{$i}-sp {
			margin-right: #{$i}px !important;
		}
		.mb#{$i}-sp {
			margin-bottom: #{$i}px !important;
		}
		.ml#{$i}-sp {
			margin-left: #{$i}px !important;
		}
		.mt#{$i}e-sp {
			margin-top: #{$i * .1}em !important;
		}
		.mr#{$i}e-sp {
			margin-right: #{$i * .1}em !important;
		}
		.mb#{$i}e-sp {
			margin-bottom: #{$i * .1}em !important;
		}
		.ml#{$i}e-sp {
			margin-left: #{$i * .1}em !important;
		}

		.pt#{$i}-sp {
			padding-top: #{$i}px !important;
		}
		.pr#{$i}-sp {
			padding-right: #{$i}px !important;
		}
		.pb#{$i}-sp {
			padding-bottom: #{$i}px !important;
		}
		.pl#{$i}-sp {
			padding-left: #{$i}px !important;
		}
		.pt#{$i}e-sp {
			padding-top: #{$i * .1}em !important;
		}
		.pr#{$i}e-sp {
			padding-right: #{$i * .1}em !important;
		}
		.pb#{$i}e-sp {
			padding-bottom: #{$i * .1}em !important;
		}
		.pl#{$i}e-sp {
			padding-left: #{$i * .1}em !important;
		}
		.w#{$i*10}-sp {
			width: #{$i*10}px !important;
		}
		.w#{$i}p-sp {
			width: #{$i}% !important;
		}

	}
	@media screen and (min-width: $breakpoint-sp +1){
		.mt#{$i}-pc {
			margin-top: #{$i}px !important;
		}
		.mr#{$i}-pc {
			margin-right: #{$i}px !important;
		}
		.mb#{$i}-pc {
			margin-bottom: #{$i}px !important;
		}
		.ml#{$i}-pc {
			margin-left: #{$i}px !important;
		}
		.mt#{$i}e-pc {
			margin-top: #{$i * .1}em !important;
		}
		.mr#{$i}e-pc {
			margin-right: #{$i * .1}em !important;
		}
		.mb#{$i}e-pc {
			margin-bottom: #{$i * .1}em !important;
		}
		.ml#{$i}e-pc {
			margin-left: #{$i * .1}em !important;
		}

		.pt#{$i}-pc {
			padding-top: #{$i}px !important;
		}
		.pr#{$i}-pc {
			padding-right: #{$i}px !important;
		}
		.pb#{$i}-pc {
			padding-bottom: #{$i}px !important;
		}
		.pl#{$i}-pc {
			padding-left: #{$i}px !important;
		}
		.pt#{$i}e-pc {
			padding-top: #{$i * .1}em !important;
		}
		.pr#{$i}e-pc {
			padding-right: #{$i * .1}em !important;
		}
		.pb#{$i}e-pc {
			padding-bottom: #{$i * .1}em !important;
		}
		.pl#{$i}e-pc {
			padding-left: #{$i * .1}em !important;
		}
		.w#{$i*10}-pc {
			width: #{$i*10}px !important;
		}
		.w#{$i}p-pc {
			width: #{$i}% !important;
		}

	}
}
.mta {
	margin-top: auto !important;
}
.mra {
	margin-right: auto !important;
}
.mba {
	margin-bottom: auto !important;
}
.mla {
	margin-left: auto !important;
}
@media screen and (min-width: $breakpoint-sp+1){
	.mta-pc {
		margin-top: auto !important;
	}
	.mra-pc {
		margin-right: auto !important;
	}
	.mba-pc {
		margin-bottom: auto !important;
	}
	.mla-pc {
		margin-left: auto !important;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.mta-sp {
		margin-top: auto !important;
	}
	.mra-sp {
		margin-right: auto !important;
	}
	.mba-sp {
		margin-bottom: auto !important;
	}
	.mla-sp {
		margin-left: auto !important;
	}
}

.fLeft { float: left !important; }
.fRight { float: right !important; }
.tLeft  { text-align: left !important; }
.tCenter { text-align: center !important; }
.tRight { text-align: right !important; }
.vat { vertical-align: top !important; }
.vam { vertical-align: middle !important; }
.vab { vertical-align: bottom !important; }

@media screen and (min-width: $breakpoint-sp + 1){
	.fLeft-pc { float: left !important; }
	.fRight-pc { float: right !important; }
	.tLeft-pc { text-align: left !important; }
	.tCenter-pc { text-align: center !important; }
	.tRight-pc { text-align: right !important; }
	.vat-pc { vertical-align: top !important; }
	.vam-pc { vertical-align: middle !important; }
	.vab-pc { vertical-align: bottom !important; }
}
@media screen and (max-width: $breakpoint-sp){
	.fLeft-sp { float: left !important; }
	.fRight-sp { float: right !important; }
	.tLeft-sp { text-align: left !important; }
	.tCenter-sp { text-align: center !important; }
	.tRight-sp { text-align: right !important; }
	.vat-sp { vertical-align: top !important; }
	.vam-sp { vertical-align: middle !important; }
	.vab-sp { vertical-align: bottom !important; }
}


.clearfix:before,
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	visibility: hidden;
}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}
.noAround {
	overflow: hidden;
	*zoom: 1;
}
.clear, .clb { clear: both; }

/*
レスポンシブ用　表示/非表示

.(sp/tab/pc)-onlyで.(sp/tab/pc)でのみ表示

.(sp/tab/pc)-hiddenで.(sp/tab/pc)でのみ非表示

つまり.sp-onlyでtab/pcは非表示に、.pc-onlyでtab/spは非表示に、

Markup:

Styleguide 1.1
============================================================*/
@media screen and (max-width: $breakpoint-sp) {
	.sp-hidden,
	.pc-only,
	.tab-only {
		display: none !important;
	}
}

@media screen and (min-width: $breakpoint-sp + 1) {
	.pc-hidden,
	.tab-only,
	.sp-only {
		display: none !important;
	}
}

/*
ページアンカーターゲット

pageAnc

Markup:
<a class="pageAnc" name="anc04" id="anc04"></a>

Styleguide 1.2
============================================================*/
.pageAnc {
	position: relative;
	width: 0;
	height: 0;
	top: 40px;
}

@media screen and (max-width: $breakpoint-sp){
	.pageAnc {
		top: -33px;
	}
}

/*
フォントサイズ

.font

Markup:
<p class="{$modifiers}">フォントサイズ</p>

.font-lll - lll
.font-ll - ll
.font-l - l
.font-s - s
.font-ss - ss
.font-sss - sss

Styleguide 1.3
============================================================*/
.font {
	&-lll {
		font-size: rem(map-get($font-size, 'pc') + 6, pc);
	}
	&-ll {
		font-size: rem(map-get($font-size, 'pc') + 4, pc);
	}
	&-l {
		font-size: rem(map-get($font-size, 'pc') + 2, pc);
	}
	&-s {
		font-size: rem(map-get($font-size, 'pc') - 2, pc);
	}
	&-ss {
		font-size: rem(map-get($font-size, 'pc') - 3, pc);
	}
	&-sss {
		font-size: rem(map-get($font-size, 'pc') - 4, pc);
	}
}
@media screen and (max-width: $breakpoint-sp){
	.font {
		&-lll {
			font-size: rem(map-get($font-size, 'sp') + 6, sp);
		}
		&-ll {
			font-size: rem(map-get($font-size, 'sp') + 4, sp);
		}
		&-l {
			font-size: rem(map-get($font-size, 'sp') + 2, sp);
		}
		&-s {
			font-size: rem(map-get($font-size, 'sp') - 2, sp);
		}
		&-ss {
			font-size: rem(map-get($font-size, 'sp') - 3, sp);
		}
		&-sss {
			font-size: rem(map-get($font-size, 'sp') - 4, sp);
		}
	}
}

/*
フォントカラー

.color

Markup:
<p class="{$modifiers}">フォントカラー</p>

.color-red - red

Styleguide 1.4
============================================================*/
.color {
}
