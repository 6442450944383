@font-face {
	font-family: "icons";
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
		// url('../fonts/icons/icons.woff2') format('woff2'),
		url('../fonts/icons/icons.woff') format('woff'),
		url('../fonts/icons/icons.ttf') format('truetype'),
		url('../fonts/icons/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icons:before {
	display: inline-block;
	vertical-align: middle;
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icons-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.icons-2x { font-size: 2em; }
.icons-3x { font-size: 3em; }
.icons-4x { font-size: 4em; }
.icons-5x { font-size: 5em; }
.icons-fw {
	width: 1.2857142857142858em;
	text-align: center;
}

.icons-calendar:before { content: "\EA01"; }
.icons-circleArrow-down:before { content: "\EA02"; }
.icons-circleArrow-left:before { content: "\EA03"; }
.icons-circleArrow-right:before { content: "\EA04"; }
.icons-circleArrow-up:before { content: "\EA05"; }
.icons-event:before { content: "\EA06"; }
.icons-examination:before { content: "\EA07"; }
.icons-japanese-a:before { content: "\EA08"; }
.icons-map:before { content: "\EA09"; }
.icons-member:before { content: "\EA0A"; }
.icons-minArrow-down:before { content: "\EA0B"; }
.icons-minArrow-left:before { content: "\EA0C"; }
.icons-minArrow-right:before { content: "\EA0D"; }
.icons-minArrow-up:before { content: "\EA0E"; }
.icons-pagetop:before { content: "\EA0F"; }
.icons-pomp:before { content: "\EA10"; }
.icons-search:before { content: "\EA11"; }
.icons-stethoscope:before { content: "\EA12"; }
.icons-tri-down:before { content: "\EA13"; }
.icons-tri-left:before { content: "\EA14"; }
.icons-tri-right:before { content: "\EA15"; }
.icons-tri-up:before { content: "\EA16"; }



//  icon 変数
//----------------------------------------------------------------------
$icons-calendar: "\EA01";
$icons-circleArrow-down: "\EA02";
$icons-circleArrow-left: "\EA03";
$icons-circleArrow-right: "\EA04";
$icons-circleArrow-up: "\EA05";
$icons-event: "\EA06";
$icons-examination: "\EA07";
$icons-japanese-a: "\EA08";
$icons-map: "\EA09";
$icons-member: "\EA0A";
$icons-minArrow-down: "\EA0B";
$icons-minArrow-left: "\EA0C";
$icons-minArrow-right: "\EA0D";
$icons-minArrow-up: "\EA0E";
$icons-pagetop: "\EA0F";
$icons-pomp: "\EA10";
$icons-search: "\EA11";
$icons-stethoscope: "\EA12";
$icons-tri-down: "\EA13";
$icons-tri-left: "\EA14";
$icons-tri-right: "\EA15";
$icons-tri-up: "\EA16";

//  font
//----------------------------------------------------------------------
@mixin icons($code) {
	content: $code;
	font-family: "icons";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}