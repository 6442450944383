@import '_config';
@import '_sprite';
@import '_sprite_mixin';
@import '_iconsMixin';
@import './_plugins/_magic';

/*
モジュール

その他モジュール

Styleguide 5.
*/

/*
パンクズ

pankuzu

Markup:
<nav class="pankuzu">
	<ul class="pankuzu_list">
		<li class="pankuzu_item"><a href="/">トップ</a></li>
		<li class="pankuzu_item">メッセージ</li>
	</ul>
</nav><!-- /.pankuzu -->

Styleguide 5.1
============================================================*/
.pankuzu {
	$this: &;
	width: $page-width;
	margin: 15px auto 0;
	& > :first-child {
		margin-top: 0;
	}
	&_list {
		margin: 0;
		letter-spacing: -.44em;
		& > :first-child {
			&:before {
				content: none;
			}
		}
	}
	&_item {
		display: inline-block;
		vertical-align: top;
		letter-spacing: normal;
		font-size: rem(12px, pc);
		color: #999;
		& > a {
			text-decoration: none;
			color: $aqua;
		}
		&:before {
			content: '　>　';
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pankuzu {
		$this: &;
		width: auto;
		padding: 0 10px;
		&_list {
		}
		&_item {
			font-size: rem(11px, sp);
		}
	}
}

/*
画像とテキスト

imgTxt

Markup:
<div class="imgTxt {$modifiers}">
	<div class="imgTxt_left"><figure><img src="http://satyr.io/300x300/dddddd?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></figure></div>
	<div class="imgTxt_body">
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
	</div>
</div><!-- /.imgTxt -->
<div class="imgTxt {$modifiers}">
	<div class="imgTxt_right"><figure><img src="http://satyr.io/300x300/dddddd?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></figure></div>
	<div class="imgTxt_body">
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		<p>ああああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
	</div>
</div><!-- /.imgTxt -->

.imgTxt-vat - 縦方向上揃え
.imgTxt-vam - 縦方向中央揃え
.imgTxt-vat - 縦方向下揃え
.imgTxt-noWrap - 縦方向下揃え

Styleguide 5.2
============================================================*/
.imgTxt {
	$this: &;
	margin: 20px 0 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	&-vat {
		align-items: flex-start;
	}
	&-vam {
		align-items: center;
	}
	&-vab {
		align-items: flex-end;
	}
	&-noWrap {
		display: block;
		overflow: hidden;
		#{$this}_left {
			float: left;
		}
		#{$this}_right {
			float: right;
		}
	}

	&_left {
		margin: 0 30px 0 0;
		flex-grow: 0;
		flex-shrink: 0;
		order: 0;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_right {
		margin: 0 0 0 30px;
		flex-grow: 0;
		flex-shrink: 0;
		order: 2;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_body {
		order: 1;
		& > :first-child {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.imgTxt {
		$this: &;
		display: block;
		& > :first-child {
			margin-top: 0;
		}
		&-noWrap {
			#{$this}_left,
			#{$this}_right {
				float: none;
				margin: 0;
			}
		}
		&_left {
			margin: 20px 0 0;
		}
		&_right {
			margin: 20px 0 0;
		}
		&_body {
			margin: 20px 0 0;
			& > :first-child {
				margin-top: 0;
			}
		}
	}
}

/*
フレーム

frame

Markup:
<div class="frame">
	<h2 class="frame_header">見出し見出し見出し</h2>
	<div class="frame_body">
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
		<p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
	</div>
</div><!-- /.frame -->

Styleguide 5.3
============================================================*/
.frame {
	$this: &;
	margin: 20px 0 0;
	border: 6px solid $aqua-light;
	border-top: none;
	&_header {
		text-align: center;
		background: $aqua-light;
		padding: 5px 10px;
		@include mintyo();
		color: $aqua;
		font-size: rem(22px, pc);
		font-weight: bold;
	}
	&_body {
		padding: 30px 40px;
		& > :first-child {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.frame {
		$this: &;
		&_header {
			font-size: rem(18px, sp);
		}
		&_body {
			padding: 15px 10px;
		}
	}
}

/*
ページリスト

pageList

Markup:
<div class="pageList">
	<ul class="pageList_list">
		<li class="pageList_item">
			<a href="">
				<p class="pageList_date">2016.10.31</p>
				<p class="pageList_name">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</p>
			</a>
		</li>
		<li class="pageList_item">
			<a href="">
				<p class="pageList_date">2016.10.31</p>
				<p class="pageList_name">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</p>
			</a>
		</li>
		<li class="pageList_item">
			<a href="">
				<p class="pageList_date">2016.10.31</p>
				<p class="pageList_name">都民講演会「脳卒中治療の最近の進歩」を12月3日開催都民講演会「脳卒中治療の最近の進歩」を12月3日開催都民講演会「脳卒中治療の最近の進歩」を12月3日開催都民講演会「脳卒中治療の最近の進歩」を12月3日開催</p>
			</a>
		</li>
		<li class="pageList_item">
			<a href="">
				<p class="pageList_date">2016.10.31</p>
				<p class="pageList_name">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</p>
			</a>
		</li>
		<li class="pageList_item">
			<a href="">
				<p class="pageList_date">2016.10.31</p>
				<p class="pageList_name">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</p>
			</a>
		</li>
	</ul>
</div><!-- /.pageList -->

Styleguide 5.4
============================================================*/
.pageList {
	$this: &;
	margin: 20px 0 0;
	&_list {
		margin: 0;
	}
	&_item {
		border-bottom: 1px dotted $gray-dot;
		& > a,
		& > div {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: inherit;
			font-size: rem(15px, pc);
			padding: 15px;
			& > * + * {
				margin-left: 35px;
			}
		}
	}
	&_date {
		margin-top: 0;
	}
	&_name {
		margin-top: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		&:before {
			@include icons($icons-circleArrow-right);
			color: $aqua;
			margin: 0 8px 0 0;
			font-size: 17px;
			vertical-align: -2px;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageList {
		$this: &;
		&_list {
		}
		&_item {
			& > a,
			& > div {
				display: block;
				padding: 10px 0;
				& > * + * {
					margin: 0;
				}
			}
		}
		&_date {
		}
		&_name {
		}
	}
}

/*
ページヘッダ

pageHeader

Markup:
<header class="pageHeader">
	<h2 class="pageHeader_title">医療機関検索</h2>
</header><!-- /.pageHeader -->

Styleguide 5.5
============================================================*/
.pageHeader {
	$this: &;
	background: url(../images/global/page_header_bg.png) center 0 no-repeat;
	background-size: cover;
	&_title {
		font-size: rem(33px, pc);
		text-align: center;
		@include mintyo();
		padding: 31px 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageHeader {
		$this: &;
		&_title {
		}
	}

}

/*
タブ

tab

Markup:
<div class="tab">
	<ul class="tab_btns">
		<li class="tab_btn tab_btn-map"><a href="/search/"><span>地図から探す</span></a></li>
		<li class="tab_btn tab_btn-subject"><a href="/search/subject.html"><span>診療科目でさがす</span></a></li>
		<li class="tab_btn tab_btn-initial"><a href="/search/initial.html"><span>50音順でさがす</span></a></li>
	</ul>
	<div class="tab_content">
		<p>内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
		<p>内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
		<p>内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
	</div><!-- /.tab_content -->
</div><!-- /.tab -->

Styleguide 5.6
============================================================*/
.tab {
	$this: &;
	overflow: hidden;
	&_btns {
		border-bottom: 3px solid $aqua;
		display: flex;
		margin: 0 0 0 -6px;
	}
	&_btn {
		margin: 0 0 0 6px;
		width: calc(100%/3 - 6px);
		& > a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			border-radius: 5px 5px 0 0;
			border: 1px solid $gray-border;
			border-bottom: none;
			text-align: center;
			text-decoration: none;
			color: inherit;
			padding: 10px 10px 7px;
			font-size: rem(20px, pc);
			@include mintyo();
			font-weight: bold;
			color: $gray-dot;
			&:before {
				display: block;
				width: 39px;
				height: 39px;
				line-height: 39px;
				text-align: center;
				border-radius: 5px;
				background: $gray-dot;
				color: #fff;
				margin: 0 10px 0 0;
			}
		}
		&-active {
			& > a {
				background: $aqua;
				color: #fff;
				&:before {
					background: #fff;
					color: $aqua;
				}
			}
		}

		&-map {
			& > a {
				&:before {
					@include icons($icons-map);
					font-size: 26px;
				}
			}
		}
		&-subject {
			& > a {
				&:before {
					@include icons($icons-examination);
					font-size: 26px;
				}
			}
		}
		&-initial {
			& > a {
				&:before {
					@include icons($icons-japanese-a);
					font-size: 24px;
				}
			}
		}
	}
	&_content {
		margin: 30px 0 0;
		& > :first-child {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.tab {
		$this: &;
		&_btns {
		}
		&_btn {
			& > a {
				font-size: rem(16px, sp);
				&:before {
					content: none;
				}
			}
			&-map {
			}
			&-subject {
			}
			&-initial {
			}
		}
		&_content {
		}
	}
}

/*
検索用マップ

searchMap

Markup:
<div class="searchMap" id="searchMap"></div>
<div class="searchMap_baloon">
	<a href="http://google.com">
		<p class="searchMap_name">神保町駅</p>
		<ul class="searchMap_tags">
			<li class="searchMap_tag">内科</li>
			<li class="searchMap_tag">消化器科</li>
			<li class="searchMap_tag">胃腸科</li>
		</ul>
	</a>
</div>

Styleguide 5.7
============================================================*/
.searchMap {
	$this: &;
	height: 660px;
	img {
		max-width: none;
		max-height: none;
	}
	&_baloon {
		& > :first-child {
			border-top: none;
			padding-top: 0;
			margin-top: 0;
		}
		& > a {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px dotted $gray-dot;
			display: block;
			text-decoration: none;
			color: inherit;
		}
	}
	&_name {
		font-size: rem(17px, pc);
		margin: 0;
		&:before {
			@include icons($icons-circleArrow-right);
			margin: 0 8px 0 0;
			font-size: 17px;
			text-indent: -25px;
			color: $aqua;
		}
	}
	&_tags {
		display: flex;
		margin: 8px 0 0 -6px;
	}
	&_tag {
		margin: 0 0 0 6px;
		border: 1px solid $aqua;
		color: $aqua;
		line-height: 1;
		padding: 5px 15px;
		border-radius: 2px;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.searchMap {
		$this: &;
		height: 250px;
	}

}

/*
検索エリア選択

searchAreas

Markup:
<div class="searchAreas">
	<ul class="searchAreas_list">
		<li class="searchAreas_item"><a href="javascript: void(0);">全域</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">野火止</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">小山</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">下里</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
		<li class="searchAreas_item"><a href="javascript: void(0);">エリア名</a></li>
	</ul>
	<p class="indent10e">※検索結果情報は常に最新情報となるよう努めていますが、場合により最新の情報ではないことがございます。<br>ご来院の際には、必ず医療機関へお問い合わせください。</p>
</div><!-- /.searchAreas -->

Styleguide 5.8
============================================================*/
.searchAreas {
	$this: &;
	margin: 20px 0 0;
	&_list {
		display: flex;
		flex-wrap: wrap;
		margin: -23px 0 0 -23px;
	}
	&_item {
		width: calc(100%/5 - 23px);
		margin: 23px 0 0 23px;
		& > a {
			display: block;
			height: 100%;
			text-decoration: none;
			color: inherit;
			padding: 30px 15px;
			background: $gray-bg;
			border-radius: 5px;
			&:before {
				@include icons($icons-tri-right);
				color: $aqua;
				margin: 0 5px 0 0;
				font-size: 10px;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.searchAreas {
		$this: &;
		&_list {
			margin: -10px 0 0 -10px;
		}
		&_item {
			width: calc(100%/2 - 10px);
			margin: 10px 0 0 10px;
			& > a {
				padding: 10px 8px;
			}
		}
	}
}

/*
パネル

panel

Markup:
<div class="panel {$modifiers}">
	<p>中身中身中身中身中身中身中身中身中身中身</p>
	<p>中身中身中身中身中身中身中身中身中身中身</p>
	<p>中身中身中身中身中身中身中身中身中身中身</p>
</div><!-- /.panel -->

.panel-border - border

Styleguide 5.9
============================================================*/
.panel {
	$this: &;
	background: $gray-bg;
	padding: 30px 40px;
	margin: 20px 0 0;
	& > :first-child {
		margin-top: 0;
	}

	&-border {
		border: 3px solid $gray-border;
		background: #fff;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.panel {
		$this: &;
		padding: 15px 10px;
	}
}

/*
診療科目選択

subjectCheck

Markup:
<form action="" class="subjectCheck">
	<p class="subjectCheck_clear"><a href="javascript: void(0);">チェックを外す</a></p>
	<ul class="subjectCheck_list">
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">内科</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">外科</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">小児科</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">眼科</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">婦人科</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">理学療法科</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">人工透析</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">テキスト</label></li>
	</ul>
	<ul class="subjectCheck_list">
		<li class="subjectCheck_item"><label><input type="checkbox" name="" id="">介護老人保健施設</label></li>
	</ul>
</form>

Styleguide 5.10
============================================================*/
.subjectCheck {
	$this: &;
	& > :first-child {
		// margin-top: -10px;
		margin-top: 0;
		padding-top: 0;
		border-top: none;
	}
	&_clear {
		text-align: right;
		& > a {
			display: inline-block;
			vertical-align: middle;
			letter-spacing: normal;
			background: $aqua;
			color: #fff;
			border-radius: 5px;
			padding: 5px 10px;
		}
	}
	&_list {
		display: flex;
		flex-wrap: wrap;
		margin: 15px 0 0 -20px;
		padding-top: 10px;
		border-top: 1px dotted $gray-dot;
	}
	&_item {
		width: calc(100%/5 - 20px);
		margin: 10px 0 0 20px;
		& > label {
			padding-left: 18px;
			text-indent: -18px;
			display: inline-block;
			vertical-align: middle;
			letter-spacing: normal;
			& > [type="checkbox"] {
				margin: 0 5px 0 0;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.subjectCheck {
		$this: &;
		&_list {
			margin-left: -10px;
		}
		&_item {
			width: calc(50% - 10px);
			margin-left: 10px;
		}
	}
}

/*
時間帯検索

dateCheck

Markup:
<div class="dateCheck">
	<p class="dateCheck_clear"><a href="javascript: void(0);">チェックを外す</a></p>
	<div class="dateCheck_body">
		<dl class="dateCheck_set">
			<dt class="dateCheck_name">曜日</dt>
			<dd class="dateCheck_list">
				<label class="dateCheck_item"><input type="checkbox" name="" id="">月曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">火曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">水曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">木曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">金曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">土曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">日曜</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">祝日</label>
			</dd>
		</dl>
		<dl class="dateCheck_set">
			<dt class="dateCheck_name">時間帯</dt>
			<dd class="dateCheck_list">
				<label class="dateCheck_item"><input type="checkbox" name="" id="">午前</label>
				<label class="dateCheck_item"><input type="checkbox" name="" id="">午後</label>
			</dd>
		</dl>
	</div>
</div><!-- /.dateCheck -->

Styleguide 5.11
============================================================*/
.dateCheck {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
	&_clear {
		text-align: right;
		& > a {
			display: inline-block;
			vertical-align: middle;
			letter-spacing: normal;
			background: $aqua;
			color: #fff;
			border-radius: 5px;
			padding: 5px 10px;
		}
	}
	&_body {
		position: relative;
		margin: 10px 0 0;
		&:before {
			content: '';
			display: block;
			width: 0;
			height: 100%;
			border-left: 1px dotted $gray-dot;
			position: absolute;
			left: 155px;
			top: 0;
			bottom: 0;
		}
	}
	&_set {
		display: flex;
		margin: 0;
		padding: 8px 0;
	}
	&_name {
		font-size: rem(16px, pc);
		font-weight: bold;
		width: 155px;
		flex-grow: 0;
		flex-shrink: 0;
	}
	&_list {
		margin: -10px 0 0 0;
	}
	&_item {
		display: inline-block;
		vertical-align: middle;
		letter-spacing: normal;
		margin: 10px 0 0 40px;
		padding-left: 18px;
		text-indent: -18px;
		& > [type="checkbox"] {
			margin: 0 5px 0 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.dateCheck {
		$this: &;
		&_body {
			&:before {
				left: calc(#{rem(16px, pc)} * 4.5);
			}
		}
		&_set {
		}
		&_name {
			width: 3em;
		}
		&_list {
		}
		&_item {
		}
	}
}

/*
病院リスト

hospitals

Markup:
<div class="hospitals">
	<div class="hospitals_list">
		<div class="hospitals_item">
			<a href="./detail.html">
				<p class="hospitals_name">東久留米クリニック</p>
				<ul class="hospitals_tags">
					<li class="hospitals_tag">内科</li>
					<li class="hospitals_tag">消化器科</li>
					<li class="hospitals_tag">胃腸科</li>
				</ul>
				<dl class="hospitals_info">
					<dt>住所：</dt>
					<dd>テキストテキストテキストテキスト</dd>
				</dl>
				<dl class="hospitals_info">
					<dt>電話番号：</dt>
					<dd>03-1234-5678</dd>
				</dl>
			</a>
		</div><!-- /.hospitals_item -->
		<div class="hospitals_item">
			<a href="./detail.html">
				<p class="hospitals_name">東久留米クリニック</p>
				<ul class="hospitals_tags">
					<li class="hospitals_tag">内科</li>
					<li class="hospitals_tag">消化器科</li>
					<li class="hospitals_tag">胃腸科</li>
				</ul>
				<dl class="hospitals_info">
					<dt>住所：</dt>
					<dd>テキストテキストテキストテキスト</dd>
				</dl>
				<dl class="hospitals_info">
					<dt>電話番号：</dt>
					<dd>03-1234-5678</dd>
				</dl>
			</a>
		</div><!-- /.hospitals_item -->
		<div class="hospitals_item">
			<a href="./detail.html">
				<p class="hospitals_name">東久留米クリニック</p>
				<ul class="hospitals_tags">
					<li class="hospitals_tag">内科</li>
					<li class="hospitals_tag">消化器科</li>
					<li class="hospitals_tag">胃腸科</li>
				</ul>
				<dl class="hospitals_info">
					<dt>住所：</dt>
					<dd>テキストテキストテキストテキスト</dd>
				</dl>
				<dl class="hospitals_info">
					<dt>電話番号：</dt>
					<dd>03-1234-5678</dd>
				</dl>
			</a>
		</div><!-- /.hospitals_item -->
		<div class="hospitals_item">
			<a href="./detail.html">
				<p class="hospitals_name">東久留米クリニック</p>
				<ul class="hospitals_tags">
					<li class="hospitals_tag">内科</li>
					<li class="hospitals_tag">消化器科</li>
					<li class="hospitals_tag">胃腸科</li>
				</ul>
				<dl class="hospitals_info">
					<dt>住所：</dt>
					<dd>テキストテキストテキストテキスト</dd>
				</dl>
				<dl class="hospitals_info">
					<dt>電話番号：</dt>
					<dd>03-1234-5678</dd>
				</dl>
			</a>
		</div><!-- /.hospitals_item -->
		<div class="hospitals_item">
			<a href="./detail.html">
				<p class="hospitals_name">東久留米クリニック</p>
				<ul class="hospitals_tags">
					<li class="hospitals_tag">内科</li>
					<li class="hospitals_tag">消化器科</li>
					<li class="hospitals_tag">胃腸科</li>
				</ul>
				<dl class="hospitals_info">
					<dt>住所：</dt>
					<dd>テキストテキストテキストテキスト</dd>
				</dl>
				<dl class="hospitals_info">
					<dt>電話番号：</dt>
					<dd>03-1234-5678</dd>
				</dl>
			</a>
		</div><!-- /.hospitals_item -->
		<div class="hospitals_item">
			<a href="./detail.html">
				<p class="hospitals_name">東久留米クリニック</p>
				<ul class="hospitals_tags">
					<li class="hospitals_tag">内科</li>
					<li class="hospitals_tag">消化器科</li>
					<li class="hospitals_tag">胃腸科</li>
				</ul>
				<dl class="hospitals_info">
					<dt>住所：</dt>
					<dd>テキストテキストテキストテキスト</dd>
				</dl>
				<dl class="hospitals_info">
					<dt>電話番号：</dt>
					<dd>03-1234-5678</dd>
				</dl>
			</a>
		</div><!-- /.hospitals_item -->
	</div>
</div><!-- /.hospitals -->

Styleguide 5.12
============================================================*/
.hospitals {
	$this: &;
	&_list {
		display: flex;
		flex-wrap: wrap;
		margin: -32px 0 0 -55px;
	}
	&_item {
		width: calc(100%/3 -  55px);
		margin: 32px 0 0 55px;
		& > a,
		& > div {
			display: block;
			text-decoration: none;
			color: inherit;
			border: 3px solid $aqua-light;
			border-radius: 5px;
			padding: 20px 25px;
			height: 100%;
			& > :first-child {
				margin-top: 0;
			}
		}
	}
	&_date {
		font-size: rem(19px, pc);
		font-weight: bold;
		border-bottom: 1px solid $gray-border;
	}
	&_name {
		font-size: rem(19px, pc);
		font-weight: bold;
		padding-left: 27px;
		text-indent: -27px;
		&:before {
			@include icons($icons-circleArrow-right);
			margin: 0 10px 0 0;
			color: $aqua;
			font-size: 17px;
			vertical-align: -2px;
		}
	}
	&_tags {
		margin: 0 0 0 21px;
		display: flex;
		flex-wrap: wrap;
	}
	&_tag {
		margin: 6px 0 0 6px;
		border: 1px solid $aqua;
		border-radius: 3px;
		color: $aqua;
		padding: 5px 17px 4px;
		line-height: 1;
	}
	&_info {
		margin: 10px 0 0 27px;
		display: flex;
		& + & {
			margin: 0 0 0 27px;
		}
		dt {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	&-narrow {
		#{$this}_list {
			margin: -20px 0 0 -15px;
		}
		#{$this}_item {
			width: calc(100%/3 -  15px);
			margin: 20px 0 0 15px;
			& > a,
			& > div {
				padding: 15px;
			}
		}
		#{$this}_info {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.hospitals {
		$this: &;
		&_list {
			margin: 0;
			display: block;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_item {
			width: auto;
			margin: 20px 0 0;
			& > a,
			& > div {
				padding: 10px;
			}
		}
		&_name {
		}
		&_tags {
		}
		&_tag {
		}
		&_info {
		}

		&-narrow {
			#{$this}_list {
				margin: 0;
			}
			#{$this}_item {
				width: auto;
				margin: 20px 0 0;
				& > a,
				& > div {
					padding: 10px;
				}
			}
			#{$this}_info {
				margin-left: 0;
			}
		}
	}
}

/*
50音

initials

Markup:
<div class="initials">
	<ul class="initials_list">
		<li class="initials_item"><a href="#anc01">あ</a></li>
		<li class="initials_item"><a href="#anc02">か</a></li>
		<li class="initials_item"><a href="#anc03">さ</a></li>
		<li class="initials_item"><a href="#anc04">た</a></li>
		<li class="initials_item"><a href="#anc05">な</a></li>
		<li class="initials_item"><a href="#anc06">は</a></li>
		<li class="initials_item"><a href="#anc07">ま</a></li>
		<li class="initials_item"><a href="#anc08">や</a></li>
		<li class="initials_item"><a href="#anc09">ら</a></li>
		<li class="initials_item"><a href="#anc10">わ</a></li>
	</ul>
</div><!-- /.initials -->

Styleguide 5.13
============================================================*/
.initials {
	$this: &;
	margin: 20px 0 0;
	&_list {
		margin: 0;
		display: flex;
		justify-content: center;
		& > :first-child {
			border-left: none;
		}
	}
	&_item {
		border-left: 1px dotted $gray-dot;
		width: 100px;
		& > a,
		& > span {
			display: block;
			text-decoration: none;
			font-size: rem(22px, pc);
			font-weight: bold;
			text-align: center;
		}
		& > a {
			color: $aqua;
		}
		& > span {
			color: $gray-txt;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.initials {
		$this: &;
		&_list {
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-left: -1px;
			& > :first-child {
				border-left: 1px dotted $gray-dot;
			}
		}
		&_item {
			width: calc(25% - 1px);
			margin: 10px 0 0;
		}
	}

}

/*
タグ

tags

Markup:
<div class="tags">
	<ul class="tags_list">
		<li class="tags_item">内科</li>
		<li class="tags_item">消化器科</li>
		<li class="tags_item">胃腸科</li>
		<li class="tags_item">小児科</li>
	</ul>
</div><!-- /.tags -->

Styleguide 5.14
============================================================*/
.tags {
	$this: &;
	margin: 20px 0 0;
	&_list {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: flex-start;
		margin: -6px 0 0 -6px;
	}
	&_item {
		margin: 6px 0 0 6px;
		border: 1px solid $aqua;
		color: $aqua;
		border-radius: 3px;
		font-size: rem(15px, pc);
		line-height: 1;
		padding: 5px 17px 3px;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.tags {
		$this: &;
		&_list {
		}
		&_item {
		}
	}
}

/*
診療受付時間テーブル

dateTable

Markup:
<table class="dateTable">
	<thead>
		<tr>
			<th></th>
			<th>月</th>
			<th>火</th>
			<th>水</th>
			<th>木</th>
			<th>金</th>
			<th class="dateTable_sat">土</th>
			<th class="dateTable_sun">日</th>
			<th class="dateTable_holi">祝祭日</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th>午前（9:00～12:00）</th>
			<td>〇</td>
			<td>〇</td>
			<td>〇</td>
			<td>〇</td>
			<td>〇</td>
			<td>〇</td>
			<td>休診</td>
			<td>休診</td>
		</tr>
		<tr>
			<th>午後（15:00～18:00）</th>
			<td>〇</td>
			<td>〇</td>
			<td>休診</td>
			<td>〇</td>
			<td>休診</td>
			<td>〇</td>
			<td>休診</td>
			<td>休診</td>
		</tr>
	</tbody>
</table><!-- /.dateTable -->

Styleguide 5.15
============================================================*/
.dateTable {
	$this: &;
	& > thead,
	& > tbody {
		& > tr {
			& > th,
			& > td {
				background: none;
			}
			& > #{$this}_sat {
				background: #ebf3ff;
			}
			& > #{$this}_sun {
				background: #ffeced;
			}
			& > #{$this}_holi {
				background: #ffeced;
			}
		}
	}
	& > thead {
		& > tr {
			& > th {
				text-align: center;
			}
		}
	}
	& > tbody {
		& > tr {
			& >td {
				text-align: center;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.dateTable {
		$this: &;
		& > thead,
		& > tbody {
			& > tr {
				& > th,
				& > td {
					padding: 8px;
				}
			}
		}
	}
}

/*
ニューステーブル

newsListTable

Markup:
<div class="newsListTable">
	<table class="newsListTable_table">
		<tbody>
			<tr>
				<td class="newsListTable_date">2016.10.31</td>
				<td class="newsListTable_name"><a href="">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</a></td>
			</tr>
			<tr>
				<td class="newsListTable_date">2016.10.31</td>
				<td class="newsListTable_name"><a href="">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</a></td>
			</tr>
			<tr>
				<td class="newsListTable_date">2016.10.31</td>
				<td class="newsListTable_name"><a href="">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</a></td>
			</tr>
			<tr>
				<td class="newsListTable_date">2016.10.31</td>
				<td class="newsListTable_name"><a href="">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</a></td>
			</tr>
			<tr>
				<td class="newsListTable_date">2016.10.31</td>
				<td class="newsListTable_name"><a href="">都民講演会「脳卒中治療の最近の進歩」を12月3日開催</a></td>
			</tr>
		</tbody>
	</table>
</div><!-- /.newsListTable -->

Styleguide 5.16
============================================================*/
.newsListTable {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
	&_table {
		table-layout: fixed;
	}
	tr {
		border-bottom: 1px dotted $gray-dot;
	}
	th,
	td {
		padding: 15px;
		border: none;
		vertical-align: middle;
	}
	&_date {
		white-space: nowrap;
		width: 8em;
	}
	&_name {
		& > span,
		& > a {
			display: block;
			color: inherit;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin: -15px 0;
			padding: 15px 0;
			&:before {
				@include icons($icons-circleArrow-right);
				color: $aqua;
				margin: 0 8px 0 0;
				font-size: 17px;
				vertical-align: -2px;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.newsListTable {
		$this: &;
		&_table {
			display: block;
		}
		tbody {
			display: block;
		}
		tr {
			display: block;
			padding: 10px 0;
		}
		th,
		td {
			display: block;
			padding: 0;
		}
		&_date {
		}
		&_name {
			& > span,
			& > a {
				margin: 0;
				padding: 0;
			}
		}
	}
}

/*
第二階層以下ページナビ

localNav

Markup:
<div class="localNav">
	<ul class="localNav_list">
		<li class="localNav_item"><a href="/about/feature#anc01" class="btn">おたふくかぜワクチン</a></li>
		<li class="localNav_item"><a href="/about/feature#anc02" class="btn">COPD検診</a></li>
		<li class="localNav_item"><a href="/about/feature#anc03" class="btn">24時間在宅</a></li>
		<li class="localNav_item"><a href="/about/feature#anc04" class="btn">特定保健指導</a></li>
		<li class="localNav_item"><a href="/about/feature#anc05" class="btn">災害対策</a></li>
	</ul>
</div>

Styleguide 5.17
============================================================*/
.localNav {
	$this: &;
	margin: 20px 0 0;
	&_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
		margin: -20px 0 0 -20px;
	}
	&_item {
		margin: 20px 0 0 20px;
		width: calc(100%/5 - 20px);
		.btn {
			height: 100%;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.localNav {
		$this: &;
		&_list {
			display: block;
			margin: 0;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_item {
			margin: 15px 0 0;
			width: auto;
		}
	}
}
