@import '_config';
@import '_sprite';
@import '_sprite_mixin';
@import '_iconsMixin';
@import './_plugins/_magic';

/*
js

js関連の最低限のスタイル　基本いじらない

Styleguide 7.
*/

/*
アコーディオン

$$$.accordion

Markup:
<dl data-acc>
	<dt><a href="javascript: void(0);" data-acc-btn>aaaaaaaaa</a></dt>
	<dd>
		<div class="inner" data-acc-body>
			<p>あああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
			<p>あああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
			<p>あああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
			<p>あああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
			<p>あああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
			<p>あああああああああああああああああああああああああああああああああああああああああああああああああああ</p>
		</div>
		<p><a href="javascript: void(0);" data-acc-btn data-acc-btn-txt="閉じる">開く</a></p>
	</dd>
</dl>

Styleguide 7.1
============================================================*/
[data-acc] {}
[data-acc-btn] {}
[data-acc-body] {
	overflow: hidden;
	visibility: hidden;
	transition: max-height .4s;
}

/*
モーダルギャラリー

lightGallery
data-modal-gallerys
data-modal-gallery

Markup:
<a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a>

Styleguide 7.2.1
============================================================*/

/*
モーダルギャラリー

lightGallery
data-modal-gallerys
data-modal-gallery

Markup:
<ul data-modal-gallerys>
	<li><a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal-gallery><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a></li>
	<li><a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal-gallery><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a></li>
	<li><a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal-gallery><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a></li>
	<li><a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal-gallery><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a></li>
	<li><a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal-gallery><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a></li>
	<li><a href="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" data-modal-gallery><img src="http://satyr.io/300x300/1?text=This+is+Dummy+Image!!!&texture=cross" alt="ダミーイメージ"></a></li>
</ul>

Styleguide 7.2.2
============================================================*/
