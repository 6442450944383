//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 1024px;

//  size
//----------------------------------------------------------------------
$page-width: 1200px;
// $main-width: 960px;
// $side-width: 210px;

$font-size: (
	'pc': 15px,
	'sp': 13px,
) !default;

//  colors
//----------------------------------------------------------------------
$txtColor: #333;

$gray-border: #e5e5e5;
$gray-dot: #bbb;
$gray-bg: #f3f3f3;
$gray-txt: #999;


$aqua: #7cb1ff;
$aqua-light: #ebf3ff;

$pink: #ff7d89;
$pink-light: #ffeced;

$green: #64d35b;

$yellow: #e6cf38;

$red: #c11227; //仮
$blue: #071985; //仮
$orange: #df6d00; //仮

//  font
//----------------------------------------------------------------------
@mixin fa($code) {
	content: $code;
	font-family: FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//px to rem
@function rem($px, $key: 'pc') {
	$value: map-get($font-size, $key);
	@return ($px / $value) * 1rem;
}

//明朝
@mixin mintyo {
	font-family: "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", serif;
}

@mixin mintyo-reset {
	font-family: 'メイリオ',Meiryo, 'ヒラギノ角ゴ Pro','Hiragino Kaku Gothic Pro', Verdana,Arial,sans-serif,'ＭＳ Ｐゴシック';
}