@import '_config';
@import '_sprite';
@import '_sprite_mixin';
@import '_iconsMixin';
@import './_plugins/_magic';

/*
グローバル

gNavとかgHeaderとか
大まかなレイアウトもここに

Styleguide 3.
*/

/*
大枠

.wrapper
.contents
.main
.side
.sectionとか

Styleguide 3.1
============================================================*/
.wrapper {
	min-height: 100%;
	min-width: $page-width;
	background: #fff;
}
.contents {
	width: $page-width;
	margin: 20px auto 0;
	& > :first-child {
		margin-top: 0;
	}

	&-column2 {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}
.main {
	width: 880px;
	flex-grow: 0;
	flex-shrink: 0;
	& > :first-child {
		margin-top: 0;
	}
}
.side {
	width: 264px;
	flex-grow: 0;
	flex-shrink: 0;
	& > :first-child {
		margin-top: 0;
	}
}
.section {
	margin: 60px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}
.form {
	margin: 60px 0 0;
	& > :first-child {
		margin-top: 0;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.wrapper {
		min-width: 0;
	}
	.contents {
		width: auto;
		padding: 0 10px;

		&-column2 {
			display: block;
		}
	}
	.main {
		width: auto;
	}
	.side {
		width: auto;
		margin: 40px 0 0;
	}
	.section {
		margin: 40px 0 0;
	}
	.form {
		margin: 40px 0 0;
	}
}

/*
グローバルヘッダ

gHeader

Markup:
<header class="gHeader">
	<div class="gHeader_inner">
		<figure class="gHeader_logo"><a href="/"><img src="/assets/images/global/logo.png" alt="東久留米市医師会"></a></figure>
		<p class="gHeader_en">Higashikurume<br>Medical Assosiation</p>
		<p class="gHeader_btn"><a href="">医師会員ページ</a></p>
		<p class="gHeader_gNavBtn"><a href="javascript: void(0);"><span></span></a></p>
	</div>
</header><!-- /.gHeader -->

Styleguide 3.2
============================================================*/
.gHeader {
	$this: &;
	border-top: 4px solid $aqua;
	&_inner {
		width: $page-width;
		margin: 0 auto;
		padding: 25px 0;
		display: flex;
		align-items: center;
	}
	&_logo {
		margin: 0;
		flex-grow: 0;
		flex-shrink: 0;
	}
	&_en {
		font-family: Arial;
		font-size: 11px;
		line-height: 1.45;
		color: #999;
		margin: 0 0 0 20px;
	}
	&_btn {
		margin: 0 0 0 auto;
		flex-grow: 0;
		flex-shrink: 0;
		& > a {
			display: block;
			text-align: center;
			text-decoration: none;
			color: $pink;
			background: $pink-light;
			font-size: 17px;
			line-height: 1;
			vertical-align: middle;
			border-radius: 3px;
			padding: 10px 50px;
			&:before {
				@include icons($icons-member);
				margin: 0 10px 0 0;
				font-size: 26px;
				vertical-align: -4px;
			}
		}
	}
	&_gNavBtn {
		display: none;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gHeader {
		$this: &;
		border-top: none;
		height: 63px;
		&_inner {
			width: auto;
			justify-content: space-between;
			padding: 10px;
			border-top: 2px solid $aqua;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			background: #fff;
			border-bottom: 1px solid rgba(#000, .2);
			box-shadow: 0 0 5px rgba(#000, .3);
			z-index: 1090;
		}
		&_logo {
			flex-shrink: 1;
			flex-grow: 0;
		}
		&_en {
			display: none;
		}
		&_btn {
			display: none;
		}
		&_gNavBtn {
			display: block;
			margin: 0 0 0 20px;
			& > a {
				display: block;
				width: 40px;
				height: 40px;
				border-radius: 2px;
				background: $aqua;
				position: relative;
				&:before,
				& > span,
				&:after {
					content: '';
					display: block;
					width: 34px;
					height: 2px;
					background: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -1px 0 0 -17px;
					opacity: 1;
					transition: all .4s;
				}
				&:before {
					transform: translateY(-8px);
				}
				&:after {
					transform: translateY(8px);
				}
			}
			&-isOpen {
				& > a {
					&:before {
						transform: translateY(0) rotate(-45deg);
					}
					& > span {
						opacity: 0;
					}
					&:after {
						transform: translateY(0) rotate(45deg);
					}
				}
			}
		}
	}
}

/*
グロナビ

gNav

Markup:
<nav class="gNav">
	<div class="gNav_inner">
		<ul class="gNav_list">
			<li class="gNav_item">
				<span class="gNav_name gNav_name-01"><a href="/search/">医療機関検索</a><button class="gNav_toggleBtn gNav_toggleBtn-isOpen"></button></span>
				<div class="gNav_sub gNav_sub-01">
					<ul class="gNav_subList">
						<li class="gNav_subItem"><a href="/search/">診療科目から探す</a></li>
						<li class="gNav_subItem"><a href="/search/">地図から探す</a></li>
						<li class="gNav_subItem"><a href="/search/">50音から探す</a></li>
					</ul>
				</div>
			</li>
			<li class="gNav_item">
				<span class="gNav_name gNav_name-02"><a href="/holiday/">休日診療案内</a><button class="gNav_toggleBtn"></button></span>
				<div class="gNav_sub gNav_sub-02">
					<ul class="gNav_subList">
						<li class="gNav_subItem"><a href="/holiday/">休日診療予定表</a></li>
						<li class="gNav_subItem"><a href="/holiday/">医療機関休診情報</a></li>
					</ul>
				</div>
			</li>
			<li class="gNav_item">
				<span class="gNav_name gNav_name-03"><a href="/event/">医療講演会</a><button class="gNav_toggleBtn"></button></span>
				<div class="gNav_sub gNav_sub-03">
					<ul class="gNav_subList">
						<li class="gNav_subItem"><a href="/event/">医療講演会</a></li>
						<li class="gNav_subItem"><a href="/event/">過去の医療講座</a></li>
					</ul>
				</div>
			</li>
			<li class="gNav_item">
				<span class="gNav_name gNav_name-04"><a href="/vaccination/">予防接種</a><button class="gNav_toggleBtn"></button></span>
				<div class="gNav_sub gNav_sub-04">
					<ul class="gNav_subList">
						<li class="gNav_subItem"><a href="/vaccination/">各種予防接種の説明</a></li>
						<li class="gNav_subItem"><a href="/vaccination/">おたふくかぜワクチン</a></li>
					</ul>
				</div>
			</li>
			<li class="gNav_item">
				<span class="gNav_name gNav_name-05"><a href="/check/">健診・検診</a><button class="gNav_toggleBtn"></button></span>
				<div class="gNav_sub gNav_sub-05">
					<ul class="gNav_subList">
						<li class="gNav_subItem"><a href="/check/">がん検診/特定健康診断/COPD</a></li>
						<li class="gNav_subItem"><a href="/check/">特定健診医療機関一覧</a></li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</nav><!-- /.gNav -->

Styleguide 3.3
============================================================*/
.gNav {
	$this: &;
	position: relative;
	&_inner {
		width: $page-width;
		margin: 0 auto;
		padding: 0 0 20px;
	}
	&_btn {
		display: none;
	}
	&_list {
		margin: 0;
		display: flex;
		width: 100%;
		& > :first-child {
			border-left: 1px dotted $gray-dot;
		}
	}
	&_item {
		flex-grow: 1;
		flex-shrink: 1;
		width: calc(100%/5);
		border-right: 1px dotted $gray-dot;
		// position: relative;
	}
	&_name {
		display: block;
		& > a {
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			color: $aqua;
			font-size: 17px;
			&:before {
				display: block;
				text-align: center;
				width: 50px;
				height: 50px;
				line-height: 50px;
				background: $aqua;
				border-radius: 50%;
				color: #fff;
				margin: 0 10px 0 0;
			}
		}
		&-01 {
			& > a {
				&:before {
					@include icons($icons-search);
					font-size: 27px;
				}
			}
		}
		&-02 {
			& > a {
				&:before {
					@include icons($icons-calendar);
					font-size: 21px;
				}
			}
		}
		&-03 {
			& > a {
				&:before {
					@include icons($icons-event);
					font-size: 24px;
				}
			}
		}
		&-04 {
			& > a {
				&:before {
					@include icons($icons-pomp);
					font-size: 25px;
				}
			}
		}
		&-05 {
			& > a {
				&:before {
					@include icons($icons-stethoscope);
					font-size: 25px;
				}
			}
		}
	}
	&_toggleBtn {
		display: none;
		outline: none;
	}
	&_sub {
		background: #fff;
		overflow: hidden;
		visibility: hidden;
		position: absolute;
		left: 0;
		right: 0;
		top: 50px;
		width: 100%;
		margin: 0;
		transition: max-height .4s;

		&-01 {
			#{$this}_subList {
				padding-left: calc(#{$page-width}/5 *0);
			}
		}
		&-02 {
			#{$this}_subList {
				padding-left: calc(#{$page-width}/5 *1);
			}
		}
		&-03 {
			#{$this}_subList {
				padding-left: calc(#{$page-width}/5 *2);
			}

		}
		&-04 {
			#{$this}_subList {
				padding-left: calc(#{$page-width}/5 *3);
			}

		}
		&-05 {
			#{$this}_subList {
				justify-content: flex-end;
			}

		}
	}
	&_subList {
		width: $page-width;
		padding: 25px 0 18px;
		margin: 0 auto;
		display: flex;
		& > :first-child {
			margin-left: 0;
		}
	}
	&_subItem {
		margin: 0 0 0 5px;
		& > a {
			display: block;
			text-decoration: none;
			color: inherit;
			min-width: 236px;
			height: 100%;
			font-size: rem(15px, pc);
			background: $gray-bg;
			padding: 12px 20px;
			border-radius: 5px;
			letter-spacing: -.44em;
			& > span {
				display: inline-block;
				vertical-align: middle;
				letter-spacing: normal;
				padding-left: 18px;
				text-indent: -18px;
				&:before {
					@include icons($icons-tri-right);
					color: $aqua;
					margin: 0 8px 0 0;
					font-size: 10px;
				}
			}
			&:after {
				display: inline-block;
				vertical-align: middle;
				letter-spacing: normal;
				width: 0;
				height: 100%;
				content: '';
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gNav {
		$this: &;
		position: fixed;
		top: 63px;
		bottom: 0;
		right: -80%;
		width: 80%;
		background: $aqua;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: right .4s;
		z-index: 1100;
		&-isOpen {
			right: 0;
		}
		&_inner {
			width: auto;
			padding: 10px;
		}
		&_btn {
			display: block;
			& > a {
				display: block;
				text-align: center;
				text-decoration: none;
				color: $pink;
				background: $pink-light;
				font-size: 17px;
				line-height: 1;
				vertical-align: middle;
				border-radius: 3px;
				padding: 10px;
				&:before {
					@include icons($icons-member);
					margin: 0 10px 0 0;
					font-size: 26px;
					vertical-align: -4px;
				}
			}
		}
		&_list {
			margin: 20px 0 0;
			display: block;
			& > :first-child {
				border-top: none;
				border-left: none;
			}
		}
		&_item {
			width: auto;
			border-right: none;
			border-top: 1px dotted rgba(#fff, .6);
		}
		&_name {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 10px 0;
			& > a {
				color: #fff;
				display: block;
				padding: 10px 5px;
				margin: -10px 0;
				&:before {
					display: inline-block;
					vertical-align: middle;
					letter-spacing: normal;
					width: 30px;
					height: auto;
					line-height: 1;
				}
			}
			&-01 {
			}
			&-02 {
			}
			&-03 {
			}
			&-04 {
			}
			&-05 {
			}
		}
		&_toggleBtn {
			display: block;
			background: #fff;
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 31px;
			border-radius: 2px;
			&:before {
				@include fa('\f067');
				color: $aqua;
				font-size: 18px;
			}

			&-isOpen {
				&:before {
					@include fa('\f068');
				}
			}
		}
		&_sub {
			position: static;
			overflow: hidden;
			padding: 0;
			margin-left: 40px;
			background: none;
			transition: max-height .4s;

			&-01,
			&-02,
			&-03,
			&-04,
			&-05 {
				#{$this}_subList {
					padding-left: 0;
				}
			}
		}
		&_subList {
			padding: 0;
			width: auto;
			display: block;
		}
		&_subItem {
			margin: 0;
			& > a {
				background: none;
				padding: 10px 0;
				color: #fff;
				min-width: 0;
				& > span {
					&:before {
						color: #fff;
					}
				}
			}
		}
	}
}

/*
フッタのナビ

footerNav

Markup:
<nav class="footerNav">
	<div class="footerNav_inner">
		<p class="footerNav_logo"><img src="/assets/images/global/logo.png" alt="東久留米市医師会"></p>
		<div class="footerNav_grid">
			<div class="footerNav_col">
				<dl class="footerNav_cates">
					<dt><a href="">東久留米市医師会について</a></dt>
					<dd><a href="">アクセス</a></dd>
					<dd><a href="">リンク</a></dd>
				</dl>
				<dl class="footerNav_cates">
					<dt><a href="">健診・検診</a></dt>
					<dd><a href="">がん検診／特定健康診断</a></dd>
					<dd><a href="">特定健診医療機関一覧</a></dd>
				</dl>
			</div><!-- /.footerNav_col -->
			<div class="footerNav_col">
				<dl class="footerNav_cates">
					<dt><a href="">医療機関検索</a></dt>
					<dd><a href="">地図からさがす</a></dd>
					<dd><a href="">診療科目でさがす</a></dd>
					<dd><a href="">50音順でさがす</a></dd>
				</dl>
				<dl class="footerNav_cates">
					<dt><a href="">医師会員ページ</a></dt>
				</dl>
			</div><!-- /.footerNav_col -->
			<div class="footerNav_col">
				<dl class="footerNav_cates">
					<dt><a href="">休日診療案内</a></dt>
					<dd><a href="">休日診療予定表</a></dd>
					<dd><a href="">医療機関休診情報</a></dd>
				</dl>
			</div><!-- /.footerNav_col -->
			<div class="footerNav_col">
				<dl class="footerNav_cates">
					<dt><a href="">今月の健康行事</a></dt>
					<dd><a href="">市民のための医療講座／都民講演会</a></dd>
					<dd><a href="">過去の医療講座</a></dd>
				</dl>
			</div><!-- /.footerNav_col -->
			<div class="footerNav_col">
				<dl class="footerNav_cates">
					<dt><a href="">予防接種</a></dt>
					<dd><a href="">各種予防接種説明</a></dd>
					<dd><a href="">おたふくかぜワクチン</a></dd>
				</dl>
			</div><!-- /.footerNav_col -->
		</div>
	</div>
</nav><!-- /.footerNav -->

Styleguide 3.4
============================================================*/
.footerNav {
	$this: &;
	margin: 70px 0 0;
	background: $gray-bg;
	&_inner {
		width: $page-width;
		margin: 0 auto;
		padding: 30px 0 40px;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_logo {
		text-align: center;
	}
	&_grid {
		display: flex;
		margin: 30px 0 0;
		& > :first-child {
			border-left: none;
		}
	}
	&_col {
		width: calc(100%/5);
		flex-grow: 1;
		flex-shrink: 1;
		border-left: 1px solid $gray-dot;
		padding: 15px 20px;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_cates {
		dt {
			& > a {
				display: block;
				text-decoration: none;
				color: $txtColor;
				font-size: rem(14px, pc);
				padding-left: 15px;
				text-indent: -15px;
				&:before {
					@include icons($icons-minArrow-right);
					margin: 0 5px 0 0;
					font-size: 10px;
				}
			}
		}
		dd {
			margin: 8px 0 0 15px;
			& > a {
				font-size: rem(14px, pc);
				line-height: 1.29;
				text-decoration: none;
				color: #999;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.footerNav {
		$this: &;
		display: none;
		&_inner {
		}
		&_logo {
		}
		&_grid {
		}
		&_col {
		}
		&_cates {
		}
	}
}

/*
グローバルフッタ

gFooter

Markup:
<footer class="gFooter">
	<div class="gFooter_inner">
		<p class="gFooter_copyright">&copy; Higashikurume Medical Assosiation. All rights reserved. </p>
	</div>
</footer><!-- /.gFooter -->
<p class="toTop"><a href="#"></a></p>

Styleguide 3.5
============================================================*/
.gFooter {
	$this: &;
	&_inner {
		width: $page-width;
		margin: 0 auto;
		padding: 20px 0;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_copyright {
		text-align: center;
		font-family: Arial;
		font-size: 10px;
	}
}
.toTop {
	$this: &;
	margin: 0;
	position: fixed;
	right: 10px;
	bottom: 10px;
	& > a {
		text-decoration: none;
		color: #bdbdbd;
		&:before {
			@include icons($icons-pagetop);
			font-size: 43px;
			line-height: 1;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gFooter {
		$this: &;
		margin: 40px 0 0;
		border-top: 1px solid $gray-border;
		&_inner {
			width: auto;
		}
		&_copyright {
		}
	}

}

/*
オーバーレイ

overlay

Markup:
<a href="javascript: void(0);" class="overlay"></a>

overlay-isOpen - open
overlay-isClose - close

Styleguide 3.5
============================================================*/
.overlay {
	$this: &;
	display: block;
	background: rgba(#000, .5);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -9999;
	opacity: 0;
	animation-duration: .4s;
	animation-fill-mode: both;
	cursor: default;
	&:hover {
		opacity: inherit;
	}
	&-isOpen {
		animation-name: overlay-isOpen;
	}
	&-isClose {
		animation-name: overlay-isClose;
	}
}
@keyframes overlay-isOpen {
	0% {
		opacity: 0;
		z-index: 1000;
	}
	100% {
		opacity: 1;
		z-index: 1000;
	}
}
@keyframes overlay-isClose {
	0% {
		opacity: 1;
		z-index: 1000;
	}
	99% {
		z-index: 1000;
	}
	100% {
		opacity: 0;
		z-index: -9999;
	}
}
