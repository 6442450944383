/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$c4e2bf10-name: 'c4e2bf10';
$c4e2bf10-x: 0px;
$c4e2bf10-y: 0px;
$c4e2bf10-offset-x: 0px;
$c4e2bf10-offset-y: 0px;
$c4e2bf10-width: 364px;
$c4e2bf10-height: 403px;
$c4e2bf10-total-width: 364px;
$c4e2bf10-total-height: 403px;
$c4e2bf10-image: '../images/sprite.png';
$c4e2bf10: (0px, 0px, 0px, 0px, 364px, 403px, 364px, 403px, '../images/sprite.png', 'c4e2bf10', );
$spritesheet-width: 364px;
$spritesheet-height: 403px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($c4e2bf10, );
$spritesheet: (364px, 403px, '../images/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
